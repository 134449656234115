// Typography
$font-size-base: 0.9rem;
$line-height-base: 1.6;


// Colors
$blue: #154871;
$navy: #252f5c;
$indigo: #6477cc;
$purple: #9561e2;
$pink: #f66d9b;
$red: rgb(255, 25, 75);
$orange: #ff8c7d;
$yellow: #fff698;
$green: #46ac92;
$teal: #4dc0c8;
$cyan: #5ab4e1;

$theme-colors: (
    "primary": #282E5C,
    "secondary": #3c3554,
    "success": #4dc0b5,
    "info":  #6cb2eb,
    "highlight": #fff698,
    "warning": #ff8f66,
    "danger": #ff4466,
    "dark": #282E5C,
    "dark-accent": #7265B3,
    "main": #489DFF,
    "light-accent": #9996aa,
    "light": #f5f5f5,
    "blue": #154871,
    "navy": #252f5c,
    "indigo": #6574cd,
    "purple": #9561e2,
    "pink": #f66d9b,
    "red": #ff4466,
    "orange": #ff8f66,
    "yellow": #fff698,
    "green": #4cac82,
    "teal": #4dc0b5,
    "cyan": #6cb2eb
);

$stop: #7775D6;
$stop2: #E935C1;
