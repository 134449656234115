// Fonts
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,700);
$font-family-base:Verdana;
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,700);
$headings-font-family:Verdana;

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

